export const pageTitle = 'Baner reklamowy z siatki mesh';
export const mainHeader = 'Siatka mesh';

export const bannerInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**${mainHeader} <span class="orange">czas realizacji</span>**
      \n 2-3 dni robocze`,
    },
    {
      article: `**${mainHeader} <span class="orange">koszt wysyłki</span>**
      \n 30,00 zł/netto. W jednej paczce zmieści się ok. 30m2 banera.`,
    },
    {
      article: `**${mainHeader} <span class="orange">wymiar</span>**
      \n Maksymalna możliwa szerokość banera w jednym kawałku to 500cm. Przy większych formatach banery są łączone z brytów.
      \n Najczęściej zamawiane wymiary siatki mesh to:
      \n * ${mainHeader} 6 x 3 m
      \n * ${mainHeader} 9 x 3 m
      \n * ${mainHeader} 12 x 3 m
      \n * ${mainHeader} 12 x 6 m
      \n * ${mainHeader} 15 x 3 m
      \n * ${mainHeader} 15 x 6 m
      `,
    },
  ],
  rightColumn: [
    {
      article: `**${mainHeader} <span class="orange">opis</span>**
      \n Materiał wykonany z poliestrowych włókien PCV o strukturze siatki. Główną zaletą tego nośnika reklamowego jest perforowane podłoże dzięki czemu przepuszcza one zarówno powietrze jak i światło. Cechą charakterystyczną tego medium jest również jego niska waga w porównaniu do innych banerów, dzięki czemu idealnie sprawdza się przy bardzo dużych ekspozycja reklamy w szczególności na budynkach czy wielkopowierzchniowych konstrukcjach.
      \n Materiał o gramaturze 270g/m2 i przeplotem 12x12.
      \n Kolorystyka: CMYK.
      \n Rozdzielczość zadruku: 360 dpi.
      \n Wydruk wykończony poprzez zgrzanie krawędzi i zaoczkowanie co 50cm.
      Na życzenie Klienta jest możliwość dodatkowego gęstszego oczkowania.
      \n Gotowy **baner reklamowy** można zamontować poprzez oczka przy użyciu np. opasek zaciskowych tzw. trytytek czy linek/gum. Możliwe jest również wykończenie zadrukowanego banera tzw. tunelami, w które można wsunąć metalowe profile, drewniane kołki czy przeciągnąć linkę.
      \n Należy pamiętać że siatka poprzez swoją strukturę posiada gorszą widoczność nadruku od pełnego, litego banera.
      \n Druk wykonywany jest najtrwalszą techniką druku solwentowego tj. twardy solwent. Jego trwałość szacuje się na ok. kilka lat. Jest on odporny na czynniki atmosferyczne, w tym także promieniowanie UV. Do zalet tego rodzaju druku, zalicza się brak konieczności laminowania wydruków. Ponadto, cechuje go odporność na odbarwienia, wodę i ścieranie.
      `,
    },
    {
      article: `**Siatka mesh <span class="orange">projekt graficzny</span>**
      \n Jeśli potrzebują Państwo wykonać projekt graficzny zachęcamy do skorzystania z naszych usług.
      `,
    },
  ],
};
